<template>
  <template v-if="!!caption">
    <ui-flexbox
      direction="column"
      align="center"
      justify="center"
      :gap="20"
      full-width
      style="height: 100%"
    >
      <ui-text variant="body2" color="textSecondary" centered>
        {{ caption }}
      </ui-text>
      <ui-button v-bind="{ ...buttonProps }" @click="handleLogin">
        Connect Wallet
      </ui-button>
    </ui-flexbox>
  </template>
  <template v-else>
    <ui-button v-bind="{ ...buttonProps }" @click="handleLogin">
      Connect Wallet
    </ui-button>
  </template>
</template>
<script setup lang="ts">
import { WALLETS, ERROR_CODE } from '@base/utils/constants'
import type { ButtonProps } from '@base/components/ui/button'

defineProps({
  caption: {
    type: String,
    default: undefined,
  },
  buttonProps: {
    type: Object as PropType<ButtonProps>,
    default: () => ({}),
  },
})

const { connectWallet } = useWallet()
const walletStore = useWalletStore()
const { address } = toRefs(walletStore)

const handleLogin = () => {
  if (address.value) return
  handleWalletSelect(WALLETS[0]) // default select MetaMask
}

const handleWalletSelect = async (wallet: (typeof WALLETS)[number]) => {
  await connectWallet(wallet.id).catch((err) => {
    if (err.code === ERROR_CODE.REQUEST_PENDING) {
      useNotify({
        type: 'error',
        message:
          'Your request is currently being processed. Please wait momentarily',
      })
      return
    }

    console.error(err)
    useNotify({
      type: 'error',
      message: err.message,
    })
  })
}
</script>
<style lang="postcss" scoped></style>
